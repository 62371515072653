import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ACCESS_TOKEN } from 'src/config';
import { useAuthentication } from 'src/features/authentication/context';

export const useWebSockets = (gatewayId: string) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const { refreshToken } = useAuthentication();

  const accessToken = Cookies.get(ACCESS_TOKEN);

  const header = {
    Authorization: accessToken,
    host: process.env.REACT_APP_HOST_WEBSOCKETS,
  };
  const encodedHeader = btoa(JSON.stringify(header));
  useEffect(() => {
    if (!gatewayId) return;
    const socket: any = new WebSocket(
      `wss://${process.env.REACT_APP_HOST_WEBSOCKETS_REAL_TIME}/?header=${encodedHeader}&payload=e30=`,
      'graphql-ws'
    );
    setWs(socket);

    socket.onopen = () => {
      const sub = {
        id: uuidv4(),
        payload: {
          data: `{"query":"subscription MySubscription {\\n  onCreatedReading(gateway_id: \\"${gatewayId}\\") {\\n    gateway_id\\n    gateway_inputs {\\n      connected_asset\\n      data_type\\n      display_name\\n      gateway_id\\n      hidden\\n      id\\n      raw_input_name\\n      raw_unit\\n      value\\n    }\\n    timestamp\\n  }\\n}","variables":null}`,
          extensions: {
            authorization: {
              Authorization: accessToken,
              host: process.env.REACT_APP_HOST_WEBSOCKETS,
            },
          },
        },
        type: 'start',
      };
      socket.send(JSON.stringify(sub));
    };

    return () => {
      socket.close();
    };
  }, [encodedHeader, accessToken, gatewayId]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event: any) => {
        const newMessage = JSON.parse(event.data);
        if (newMessage.type === 'connection_error') {
          ws.close();
          refreshToken();
        }
        if (newMessage.type === 'data') {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      };
    }
  }, [refreshToken, ws]);

  return { messages };
};
