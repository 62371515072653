import FloatingAddLog from '../components/FloatingAddLog';
import LogForm from '../components/LogForm';
import { CustomWaterLoading, MenuPopoverItem } from 'src/components';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { useEffect, useRef, useState, useCallback } from 'react';
import { ApiState, LogItem } from '../types';
// import {FilterItem} from "../types"
import { Container } from '@mui/material';
import LogsList from '../components/LogsList';
// import LogFilters from '../components/LogFilters';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { useResponsive } from 'src/hooks';
import { LogsService } from 'src/services/logs';
import parseLogPayload from '../utils/parseLogPayload';
import { useSnackbar } from 'notistack';
import RestoreLogConfirmDialog from '../components/RestoreLogConfirmDialog';
import NoLogsPlaceholder from '../components/NoLogsPlaceholder';
import getAttachments from '../utils/getAttachments';

const Logs = () => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const filtersRef = useRef<any>('');
  // const [filters, setFilters] = useState('');
  const [logs, setLogs] = useState<{ loaded: boolean; data: LogItem[] }>({
    loaded: false,
    data: [],
  });
  const isMobile = useResponsive('down', 'md');
  const { append, get, remove } = useQueryParamsActions();
  const [isCreatingLog, setIsCreatingLog] = useState(false);
  const [isDeletingLog, setIsDeletingLog] = useState(false);
  const [isRevertingLog, setIsRevertingLog] = useState(false);
  const [logToRevert, setLogToRevert] = useState<null | LogItem>(null);

  const [formsState, setFormsState] = useState<
    ApiState<
      {
        name: string;
        id: string;
        allowComments: boolean;
        isDefault: boolean;
      }[]
    >
  >({
    loading: true,
    data: [],
  });
  const formId = get('formId');
  const logId = get('logId');
  const version = get('version');
  const form = formsState.data.find((form) => form.id === formId);
  const fetchedFormsRef = useRef<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const onClose = useCallback(() => {
    remove('formId');
    remove('logId');
    remove('version');
  }, [remove]);

  const fetchLogs = useCallback(
    async (filters = '') => {
      try {
        if (!customerId || !siteId) throw Error('Customer or site is not provided.');
        setLogs((prevState) => ({ ...prevState, loaded: false }));
        const logs = await LogsService.getAll(customerId!, siteId!, 'includeInactive=true');
        setLogs({
          data: logs,
          loaded: true,
        });
      } catch (err) {
        setLogs({ data: [], loaded: true });
      }
    },
    [setLogs, customerId, siteId]
  );

  // const onFiltersChange = useCallback(async (filter: FilterItem[]) => {
  //   const filters: string[] = [];

  //   filter.forEach((filt) => {
  //     if (filt.name === 'deleted_logs') {
  //       filters.push('includeInactive=true');
  //     }
  //   });

  //   setFilters(filters.join('&'));
  // }, []);

  // Log operations
  const handleCreateLog = async (data: any, logFormId?: string, allowComments?: boolean) => {
    if (!form?.id && !logFormId) return;

    const log: any = parseLogPayload(
      data,
      (form?.id || logFormId) as string,
      !!allowComments,
      logId
    );

    const attachments = getAttachments(data);

    if (!customerId || !siteId) return;

    const maxSizeInMB = 10; // 10 MB
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to Bytes

    let hasTooLongFile = false;
    Object.values(attachments).forEach((attachment: any) => {
      if (Array.isArray(attachment)) {
        attachment.forEach((file: any) => {
          if (file?.size > maxSizeInBytes) {
            hasTooLongFile = true;
          }
        });
      }
    });

    if (hasTooLongFile)
      return enqueueSnackbar('Uploaded file size is too long! (Maximum is: 10mb)', {
        variant: 'error',
      });

    try {
      setIsCreatingLog(true);
      await LogsService.add(customerId, siteId, log, attachments, logId ?? undefined);
      await fetchLogs(filtersRef.current);
      enqueueSnackbar('Log successfully created!', { variant: 'success' });
      setIsCreatingLog(false);
      onClose();
    } catch (err) {
      enqueueSnackbar('Log creation failed!', { variant: 'error' });
      setIsCreatingLog(false);
    }
  };
  const handleDeleteLog = async (logId: string) => {
    try {
      setIsDeletingLog(true);
      await LogsService.patch(customerId!, siteId!, logId, { status: 'inactive' });

      enqueueSnackbar('Log successfully deleted!', { variant: 'success' });
      setIsDeletingLog(false);
      onClose();
      await fetchLogs(filtersRef.current);
    } catch (err) {
      enqueueSnackbar('Log deletion failed! Message', { variant: 'error' });
      setIsDeletingLog(false);
    }
  };
  const handleRevertLog = async (logId: string) => {
    try {
      setIsRevertingLog(true);
      await LogsService.patch(customerId!, siteId!, logId, { status: 'active' });

      enqueueSnackbar('Log successfully reverted!', { variant: 'success' });
      setIsRevertingLog(false);
      setLogToRevert(null);
      await fetchLogs(filtersRef.current);
    } catch (err) {
      enqueueSnackbar('Log revert failed! Message', { variant: 'error' });
      setIsRevertingLog(false);
      setLogToRevert(null);
    }
  };

  useEffect(() => {
    // filtersRef.current = filters;
    fetchLogs();
  }, [fetchLogs]);

  useEffect(() => {
    if (!fetchedFormsRef.current) {
      (async () => {
        const response = await api.get('/sites/' + customerId + '/' + siteId + '/assigned-forms', {
          timeout: 150000,
        });
        setFormsState({ loading: false, data: response.data });
        fetchedFormsRef.current = true;
      })();
    }
  }, [customerId, siteId]);

  const formsNotAvailable = !formsState.loading && (!formsState.data || !formsState.data.length);

  return (
    <Container
      maxWidth={false}
      sx={{
        paddingInline: '0px !important',
        display: 'flex',
        height: isMobile ? '100%' : 'calc(100% - 138px)',
        flexDirection: 'column',
      }}
    >
      {/* <LogFilters onChange={onFiltersChange} /> */}
      <RestoreLogConfirmDialog
        loading={isRevertingLog}
        onConfirm={async () => handleRevertLog(logToRevert!.id)}
        onClose={() => {
          setLogToRevert(null);
        }}
        open={!!logToRevert}
        logTitle={logToRevert?.title || ''}
      />
      {logs.loaded ? (
        logs.data.length ? (
          <LogsList
            onLogRevert={setLogToRevert}
            onLogSelected={(log) => {
              append('logId', log.id);
            }}
            logs={logs.data}
          />
        ) : (
          <NoLogsPlaceholder />
        )
      ) : (
        <CustomWaterLoading />
      )}

      {/* Form modal */}
      {form || logId ? (
        <LogForm
          onChangeVersionHistory={(historyItem, lastItem) => {
            if (lastItem && lastItem.version === historyItem.version) return remove('version');
            append('version', historyItem.version + '');
          }}
          version={version ?? undefined}
          isCreatingLog={isCreatingLog}
          isDeletingLog={isDeletingLog}
          logId={logId}
          onSubmit={handleCreateLog}
          onDelete={handleDeleteLog}
          onClose={onClose}
          isDefault={form?.isDefault}
          id={form?.id}
        />
      ) : (
        <></>
      )}
      <FloatingAddLog
        disabled={formsState.loading || formsNotAvailable}
        renderActions={(onClose) => (
          <>
            {formsState.data.map((form) => (
              <MenuPopoverItem
                key={form.id}
                handleClick={() => {
                  onClose();

                  remove('logId');
                  append('formId', form.id);
                }}
                customContent={<>{form.name}</>}
              />
            ))}
          </>
        )}
      />
    </Container>
  );
};

export default Logs;
