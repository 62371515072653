import { Box, Button } from '@mui/material';
import { WidgetService } from 'src/services';
import { WidgetType } from '../../types/types';
import { useAuthentication } from 'src/features/authentication/context';

type EditWidgetButtonsBoxProps = {
  toggleEditMode: () => void;
  isEditMode: boolean;
  widgets: WidgetType[];
  layout: any;
  getAll: () => void;
};

export const EditWidgetButtonsBox = ({
  toggleEditMode,
  isEditMode,
  widgets,
  layout,
  getAll,
}: EditWidgetButtonsBoxProps) => {
  const {
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();
  const toggleEditModeHandler = () => {
    toggleEditMode();
  };

  const onEditHandler = () => {
    if (!customerId || !siteId) return;
    widgets.forEach((widget: any) => {
      const { x, y } = widget.positioning;
      const layoutWidget = layout.find((item: any) => item.i === widget.id);
      if (x !== layoutWidget.x || y !== layoutWidget.y) {
        WidgetService.edit({
          customerId: customerId,
          siteId: siteId,
          widgetId: widget.id,
          data: {
            positioning: {
              x: layoutWidget.x,
              y: layoutWidget.y,
              width: layoutWidget.w,
              height: layoutWidget.h,
            },
          },
        }).catch((error) => console.error(error));
      }
    });

    toggleEditModeHandler();
  };

  const onDiscardHandler = () => {
    getAll();
    toggleEditModeHandler();
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: '30px' }}>
      {isEditMode ? (
        <>
          <Button
            sx={{ mr: '10px' }}
            size="medium"
            variant="outlined"
            data-sm="discard-button"
            onClick={onDiscardHandler}
          >
            Discard
          </Button>
          <Button
            size="medium"
            variant="contained"
            data-sm="save-view-button"
            onClick={onEditHandler}
          >
            Save View
          </Button>
        </>
      ) : (
        <Button
          sx={{ mr: '10px' }}
          size="medium"
          data-sm="edit-view-button"
          variant="outlined"
          onClick={toggleEditModeHandler}
        >
          Edit View
        </Button>
      )}
    </Box>
  );
};
