import { styled, Theme } from '@mui/material/styles';
import { Box } from '@mui/material';

type StyledMainTopTypes = {
  theme: Theme;
  isMobile: boolean;
  isTransparent?: boolean;
};

type StyledPageContentTypes = {
  isMobile: boolean;
  theme: Theme;
  noPadding?: boolean;
};

type StyledAppLayoutType = {
  theme: Theme;
};

const StyledAppLayout = styled(Box)(({ theme }: StyledAppLayoutType) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));

const sideBarHeader = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 10px',
};

const mainContent = {
  width: '100%',
  overflowX: 'hidden',
  //backgroundColor: '#F4F6F8',
};

const mainContentMap = {
  height: '100vh',
  display: 'grid',
  gridTemplateRows: '1fr',
  '@media (min-width:900px)': {
    gridTemplateRows: 'auto 1fr',
  },
};

const StyledMainTop = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isTransparent',
})(({ isMobile, theme, isTransparent }: StyledMainTopTypes) => ({
  position: isMobile ? 'sticky' : 'static',
  top: 0,
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  '@media (max-width:900px)': {
    backgroundColor: isTransparent ? 'transparent' : theme.palette.background.paper,
  },
}));

const iconBox = {
  paddingLeft: '20px',
  height: '24px',
};

const mapIconBox = {
  paddingRight: '20px',
  height: '24px',
};

const StyledPageContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'noPadding',
})(({ isMobile, noPadding, theme }: StyledPageContentTypes) => ({
  padding: (() => {
    if (noPadding) {
      return '0';
    }
    return isMobile ? ' 0 20px' : ' 0 16px';
  })(),
  backgroundColor: theme.palette.background.paper,
}));

export {
  StyledAppLayout,
  sideBarHeader,
  mainContent,
  StyledMainTop,
  iconBox,
  StyledPageContent,
  mainContentMap,
  mapIconBox,
};
