import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import formatDateRange from '../utils/formatDateRange';

interface ILogDateProps {
  startDate: string;
  endDate?: string;
}

const LogDate: FunctionComponent<ILogDateProps> = ({ startDate, endDate }) => (
  <Typography sx={{ fontSize: '12px', color: '#566369' }} variant="body1">
    {formatDateRange(startDate, endDate)}
  </Typography>
);

export default LogDate;
