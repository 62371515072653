import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import { ACCOUNT_ISSUE_PATH, LOGIN_PATH, TRENDS_HEADER } from '../../config';
import { AppLayout } from 'src/layouts';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { TrendsPageNew } from '../../pages';
import { ElementProps } from '../../types/element';
import { LoadingElement } from '../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';

export const TrendsElementNew = ({ isSuperAdmin }: ElementProps) => {
  const { isCustomerAccessAllowed, loggedIn, customerId, getCurrentRole, user } =
    useAuthentication();

  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;

  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && !isCustomerAccessAllowed.value)
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };

  const TrendsRouteElement = () => (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={isSuperAdmin || isCustomerAccessAllowed.value}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <AppLayout
        headerTitle={TRENDS_HEADER}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SITES_MANAGEMENT
        }
      >
        <TrendsPageNew />
      </AppLayout>
    </Protected>
  );

  const getTrendsElement = () => {
    if (!loggedIn.loaded) return <LoadingElement />;

    if (loggedIn.loaded && !loggedIn.value) return <TrendsRouteElement />;

    if (!customerId.value || !customerId.loaded) return <LoadingElement />;

    if (isSuperAdmin && loggedIn.loaded) return <TrendsRouteElement />;

    if (!isCustomerAccessAllowed.loaded) return <LoadingElement />;

    return <TrendsRouteElement />;
  };

  return getTrendsElement();
};
