import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { Protected } from 'src/features/protected-route/components';
import {
  ACCOUNT_ISSUE_PATH,
  LOGIN_PATH,
  REPORT_NEW_HEADER,
  REPORTS_NEW_PATH,
} from '../../../config';
import { AppLayout } from 'src/layouts';
import {
  NAV_ITEMS_HEADER_SITES_MANAGEMENT,
  getNavItemsHeaderSiutesManagementSAAndAA,
} from 'src/features';
import { LoadingElement } from '../../LoadingElement';
import { AccessRoles } from 'src/features/user-account-details/types';
import { ASSET_REPORT_NAV_ITEMS, DATA_REPORT_NAV_ITEMS } from '../config';
import BuildReport from 'src/features/reports/pages/BuildReport';
import { AssetService, ReportsService } from 'src/services';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import {
  ASSET_REPORT_INITIAL_STATE_POPULATED,
  DATA_REPORT_INITIAL_STATE_POPULATED,
  initialFilters,
} from 'src/features/reports/config/config';
import { FormProvider } from 'src/components/minimals/form';
import TemplateContextProvider from 'src/features/reports/context/templateContext';
import { useSnackbar } from 'notistack';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { FormDataType, getFilterResponse } from 'src/features/reports';
import { CustomWaterLoading } from 'src/components';
import useTemplateQuery from 'src/features/reports/hooks/useTemplateQuery';
import { Container, Dialog, DialogTitle } from '@mui/material';
import useTimezone from 'src/features/reports/hooks/useTimezone';
import SitesContextProvider from 'src/features/reports/context/sitesContext';

type Props = {
  loggedIn: {
    value: boolean;
    loaded: boolean;
  };
  isSuperAdmin: boolean;
  isCustomerAccessAllowed: {
    value: boolean;
    loaded: boolean;
    isCustomerActive: boolean;
    isUserActive: boolean;
  };
  customerId: {
    value: string | null;
    loaded: boolean;
  };
};

const ReportAssetRouteElement: FunctionComponent<any> = ({ isSuperAdmin }) => {
  const { isCustomerAccessAllowed, loggedIn, customerId, getCurrentRole, user, sites } =
    useAuthentication();
  const reportRetrievedRef = useRef(false);
  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;
  const location = useLocation();
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const { parseTimezone } = useTimezone();
  const { navigateWithParams, get } = useQueryParamsActions();
  const { enqueueSnackbar } = useSnackbar();
  const [undoChangesDialogOpen, setUndoChangesDialogOpen] = useState(false);
  const templateId = get('templateId');
  const onTemplateError = useCallback(() => {
    navigateWithParams(REPORTS_NEW_PATH);
    enqueueSnackbar('Template with id ' + templateId + ' failed to load.', {
      variant: 'error',
    });
  }, [enqueueSnackbar, navigateWithParams, templateId]);

  const [titleLoading, setTitleLoading] = useState<boolean>(true);

  const isHistory = location.pathname.includes('/build-report/history');
  const methods = useForm<FormDataType>({
    defaultValues:
      sessionStorage.getItem('report_type') === 'ASSET'
        ? ASSET_REPORT_INITIAL_STATE_POPULATED
        : DATA_REPORT_INITIAL_STATE_POPULATED,
  });
  const {
    loaded,
    data: templateData,
    assetProperties,
    fetched,
  } = useTemplateQuery({ onTemplateError, methods });
  const reportType = methods.watch('header.reportType');
  const defaultTitleRef = useRef('');
  const reportTitleChangedRef = useRef(false);

  const defaultTitle = `New Report`;
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    if (!customerId.value || reportTitleChangedRef.current) return;

    const fetchReportCount = async () => {
      try {
        const response = await ReportsService.getCount(customerId.value as string);
        if (!templateId) {
          const titleReportCount = `New Report${response ? ' ' + response : ''}`;
          setTitle(titleReportCount);
          defaultTitleRef.current = titleReportCount;
          setTitleLoading(false);
        }
      } catch (err) {
        enqueueSnackbar('Title name failed to load.', { variant: 'error' });
        setTitle(defaultTitle);
        defaultTitleRef.current = defaultTitle;
        setTitleLoading(false);
        console.error(err);
      }
    };

    fetchReportCount();
  }, [customerId, templateId, defaultTitle, setTitleLoading, enqueueSnackbar]);
  useEffect(() => {
    if (!reportRetrievedRef.current) {
      reportRetrievedRef.current = true;
      const reportTypeFromSessionStorage = sessionStorage.getItem('report_type');
      if (reportTypeFromSessionStorage) {
        if (reportTypeFromSessionStorage === 'DATA') {
          methods.setValue('header.reportType', { id: 'DATA', label: 'Data Report' });
        } else {
          methods.setValue('header.reportType', { id: 'ASSET', label: 'Asset Report' });
        }
      } else {
        enqueueSnackbar('Could not get report type', { variant: 'error' });
        navigateWithParams(REPORTS_NEW_PATH);
      }
    }
  }, [navigateWithParams, enqueueSnackbar, methods]);

  const fetchedRef = useRef(false);

  useEffect(() => {
    if (loaded && templateId && !fetchedRef.current && templateData.reportType) {
      fetchedRef.current = true;
      const { timezone, title, reportType, state } = templateData;
      defaultTitleRef.current = title;
      setTitleLoading(false);
      setTitle(title);
      methods.setValue('header.timeZone', parseTimezone(timezone) as any);

      methods.setValue('data', state);
      methods.setValue(
        'header.reportType',
        reportType === 'DATA'
          ? { id: 'DATA', label: 'Data Report' }
          : { id: 'ASSET', label: 'Asset Report' }
      );

      setTitleLoading(false);
    }
    // eslint-disable-next-line
  }, [templateData, templateId, methods, setTitleLoading, setTitle, loaded]);
  const getUniqueRedirectPath = () => {
    if (!loggedIn.value) return LOGIN_PATH;
    if (!isSuperAdmin && !isCustomerAccessAllowed.value)
      return ACCOUNT_ISSUE_PATH + '?reason=Access Denied';
  };
  const navItems =
    typeof reportType === 'object' && (reportType as FormDataType).id === 'DATA'
      ? DATA_REPORT_NAV_ITEMS
      : ASSET_REPORT_NAV_ITEMS;

  return (
    <Protected
      isAuthenticated={loggedIn.value}
      isAuthorized={isSuperAdmin || isCustomerAccessAllowed.value}
      uniqueRedirectPath={getUniqueRedirectPath()}
    >
      <Dialog
        onClose={() => {
          setUndoChangesDialogOpen(false);
        }}
        open={undoChangesDialogOpen}
      >
        <DialogTitle>Undoing changes...</DialogTitle>
      </Dialog>
      <AppLayout
        onTitleChange={(title) => {
          reportTitleChangedRef.current = true;
          setTitle(title);
        }}
        onNavigateBackClick={() => {
          navigateWithParams(REPORTS_NEW_PATH);
        }}
        extendedHeaderTitle={isHistory ? 'History' : titleLoading ? 'Loading...' : title}
        isTitleEditable={!titleLoading && !isHistory}
        activeExtendedNavItem={navItems.find((el) => location.pathname.includes(el.url))?.title}
        defaultExtendedHeaderTitle={defaultTitle}
        isExtendedHeader={true}
        extendedNavItems={navItems}
        headerTitle={REPORT_NEW_HEADER}
        headerNavItems={
          isSuperAdmin || isAccountAdmin
            ? getNavItemsHeaderSiutesManagementSAAndAA(user, isSuperAdmin)
            : NAV_ITEMS_HEADER_SITES_MANAGEMENT
        }
      >
        <FormProvider methods={methods}>
          {!loaded ? (
            <Container
              sx={{
                display: 'flex',
                width: '100vw',
                minHeight: '450px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomWaterLoading />
            </Container>
          ) : (
            <SitesContextProvider
              methods={methods}
              filtersLoaded={filtersLoaded}
              onLoad={() => {
                setFiltersLoaded(true);
              }}
              template={templateData}
              isAsset={
                (typeof reportType === 'string' && reportType === 'ASSET') ||
                (reportType as FormDataType).id === 'ASSET'
              }
            >
              <TemplateContextProvider
                title={title}
                template={templateData}
                loaded={fetched}
                assetProperties={assetProperties}
                undoChanges={async (defaultSites) => {
                  if (
                    (typeof reportType === 'string' && reportType === 'ASSET') ||
                    (reportType as any).id === 'ASSET'
                  ) {
                    setUndoChangesDialogOpen(true);
                    let assets: any = [];
                    if (templateData.state?.filters?.assetFilters?.assets?.length) {
                      assets = await AssetService.getAllGlobal(customerId.value!);
                    }
                    methods.setValue(
                      'filters',
                      templateId
                        ? getFilterResponse(templateData.state.filters, sites.value, assets)
                        : { ...initialFilters, selectedItems: defaultSites }
                    );
                  }
                  setUndoChangesDialogOpen(false);
                  setTitle(templateData?.title || defaultTitleRef.current || defaultTitle);
                  methods.setValue('data', templateData.state);
                  methods.setValue('header.timeZone', parseTimezone(templateData.timezone) as any);
                }}
                reportType={
                  (typeof reportType === 'string'
                    ? reportType
                    : (reportType as FormDataType).id) as string
                }
              >
                <BuildReport disableHeaderFormBuilder={isHistory} title={title} />
              </TemplateContextProvider>
            </SitesContextProvider>
          )}
        </FormProvider>
      </AppLayout>
    </Protected>
  );
};

export const BuildReportsRouteElement = ({
  loggedIn,
  isSuperAdmin,
  isCustomerAccessAllowed,
  customerId,
}: Props) => {
  if (!loggedIn.loaded) return <LoadingElement />;

  if (loggedIn.loaded && !loggedIn.value)
    return <ReportAssetRouteElement isSuperAdmin={isSuperAdmin} />;

  if (!customerId.value || !customerId.loaded) return <LoadingElement />;

  if (isSuperAdmin && loggedIn.loaded)
    return <ReportAssetRouteElement loggedIn={loggedIn} isSuperAdmin={isSuperAdmin} />;

  if (!isCustomerAccessAllowed.loaded) return <LoadingElement />;

  return <ReportAssetRouteElement isSuperAdmin={isSuperAdmin} />;
};
