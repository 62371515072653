import { AssetType, DailyRuntime } from '../types/types';
import { PUMP_RUNTIME_TAG_NAME } from '../config';
import { useWebSockets } from './useWebSockets';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

type usePumpRuntimeDataTypes = {
  customerId: string | null;
  siteId: string | null;
  asset: AssetType;
};

const formatRuntimeValue = (runtime: any) => {
  if (!runtime) return 0;

  const toNumber = Number(runtime.value);
  if (Number.isNaN(toNumber)) {
    return 0;
  }

  return Number((toNumber / 3600).toFixed(2));
};

export const usePumpRuntimeData = ({ customerId, siteId, asset }: usePumpRuntimeDataTypes) => {
  const [dailyRuntimes, setDailyRuntimes] = useState<DailyRuntime[] | null>(null);
  const assetTag = asset?.assignedTags?.find((tag) => tag?.tagName === PUMP_RUNTIME_TAG_NAME);
  const { messages } = useWebSockets(assetTag?.assignedGatewayId!);

  const latestMessage = messages[messages.length === 0 ? 0 : messages.length - 1];
  const gatewayInputs = latestMessage?.payload.data.onCreatedReading.gateway_inputs;
  const timestamp = latestMessage?.payload.data.onCreatedReading.timestamp;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === assetTag?.assignedTagId
  );
  const gatewayId = assetTag?.assignedGatewayId;

  useEffect(() => {
    if (gatewayId && findInput && timestamp) {
      const date = format(new Date(timestamp * 1000), 'MM.dd');
      const runtimeValue = formatRuntimeValue(findInput);
      setDailyRuntimes((prev) => {
        if (prev === null) {
          return [{ day: date, value: runtimeValue }];
        }
        const runtimes = [...prev];
        runtimes[runtimes.length - 1] = { day: date, value: runtimeValue };
        return runtimes;
      });
    }
  }, [gatewayId, findInput, timestamp]);

  useEffect(() => {
    if (customerId && gatewayId) {
      setDailyRuntimes(null);

      return;
    }
    setDailyRuntimes([]);
  }, [customerId, siteId, gatewayId, assetTag?.assignedTagId]);

  return { dailyRuntimes };
};
