import { useEffect, useState } from 'react';
import { useWebSockets } from './useWebSockets';
import { TagResponse } from 'src/features/alarm-rules/types';
import { convertToNumber, getIndexOfLastElement } from '../utils';

type useTrendWidgetDataArgumentsTypes = {
  gatewayInput: TagResponse;
  customerId: string | null;
};
export const useTrendWidgetData = ({ gatewayInput }: useTrendWidgetDataArgumentsTypes) => {
  const [inputData, setInputData] = useState<any[] | null>(null);
  const gatewayId = gatewayInput?.gatewayId;

  const { messages } = useWebSockets(gatewayId!);
  const indexOfLastElement = getIndexOfLastElement(messages);
  const dataSocket = messages[indexOfLastElement];
  const timestamp = Number(dataSocket?.payload?.data.onCreatedReading?.timestamp);
  const gatewayInputs = dataSocket?.payload.data.onCreatedReading.gateway_inputs;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === gatewayInput?.id
  );

  useEffect(() => {
    if (findInput) {
      setInputData((prevState: any[] | null) => {
        if (prevState === null) {
          return [
            {
              inputName: findInput.displayName,
              rawUnit: findInput.rawUnit,
              timestamp: new Date(timestamp * 1000).toString(),
              input: convertToNumber(findInput.value),
            },
          ];
        }
        return [
          {
            inputName: findInput.displayName,
            rawUnit: findInput.rawUnit,
            timestamp: new Date(timestamp * 1000).toString(),
            input: convertToNumber(findInput.value),
          },
          ...prevState,
        ];
      });
    }
  }, [findInput, timestamp]);

  return { inputData };
};
