import { api } from 'src/api.client';
import { LogCommentPayload, LogItem } from 'src/features/logs/types';
import { SITES_ROOT } from '../api';
import Cookies from 'js-cookie';

const queryLogs = () => [];

// SUMMARY:
// Endpoint for log creation.
const add = async (
  customerId: string,
  siteId: string,
  data: LogItem,
  attachements: any,
  logId?: string
) => {
  const formData = new FormData();
  formData.append('logPayload', JSON.stringify(data));

  Object.keys(attachements).forEach((fieldName: any) => {
    attachements[fieldName].forEach((file: any) => {
      formData.append(`${fieldName}[]`, file);
    });
  });

  const response = await api({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: true,
    timeout: 150000,
    url: `${SITES_ROOT}/${siteId}/logs${logId ? '/' + logId : ''}?customerId=${customerId}`,
    data: formData,
  });

  return response.data;
};
const getLog = async (customerId: string, siteId: string, logId: string, version?: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${siteId}/logs/${logId}${version ? '?version=' + version : ''}`,
  });

  return response.data;
};
const addComment = async (
  siteId: string,
  customerId: string,
  logId: string,
  data: LogCommentPayload
) => {
  const response = await api({
    method: 'post',
    withCredentials: true,
    timeout: 150000,
    url: `${SITES_ROOT}/${siteId}/logs/${logId}/comments?customerId=${customerId}`,
    data,
  });

  return response.data;
};
const getAttachment = async (
  logId: string,
  attachmentId: string,
  siteId: string,
  customerId: string
) => {
  const response = await api({
    method: 'get',
    timeout: 150000,
    responseType: 'blob',
    withCredentials: true,
    url: `${SITES_ROOT}/${siteId}/logs/${logId}/attachments/${attachmentId}?customerId=${customerId}`,
  });
  const file = new File([response.data], response.headers['Content-Disposition'], {
    type: response.headers['content-type'],
  });
  return file;
};
const patchComment = async (
  siteId: string,
  customerId: string,
  logId: string,
  commentId: string,
  data: LogCommentPayload
) => {
  const response = await api({
    method: 'patch',
    timeout: 150000,
    withCredentials: true,
    url: `${SITES_ROOT}/${siteId}/logs/${logId}/comments/${commentId}?customerId=${customerId}`,
    data,
  });

  return response.data;
};
const deleteComment = async (
  siteId: string,
  logId: string,
  customerId: string,
  commentId: string
) => {
  const response = await api({
    method: 'delete',
    timeout: 150000,
    withCredentials: true,

    url: `${SITES_ROOT}/${siteId}/logs/${logId}/comments/${commentId}?customerId=${customerId}`,
  });

  return response.data;
};
const getComment = async (customerId: string, siteId: string, logId: string, version?: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${siteId}/logs/${logId}/comments`,
  });

  return response.data;
};
const getHistory = async (customerId: string, siteId: string, logId: string) => {
  const response = await api({
    method: 'get',
    url: `${SITES_ROOT}/${siteId}/logs/${logId}/history`,
  });

  return response.data;
};
const patch = async (customerId: string, siteId: string, logId: string, data: any) => {
  const response = await api({
    method: 'patch',
    withCredentials: true,
    timeout: 150000,
    headers: {
      Cookie: Cookies.get('access_token'),
    },
    url: `${SITES_ROOT}/${siteId}/logs/${logId}?customerId=${customerId}`,
    data,
  });

  return response;
};
const getAll = async (customerId: string, siteId: string, filters: string) => {
  const response = await api({
    method: 'get',
    timeout: 150000,

    url: `${SITES_ROOT}/${siteId}/logs${filters ? '?' + filters : ''}`,
  });

  return response.data;
};

export const LogsService = {
  addComment,
  patchComment,
  deleteComment,
  getHistory,
  queryLogs,
  getAttachment,
  getLog,
  add,
  getAll,
  patch,
  getComment,
};
