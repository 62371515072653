import Card from 'src/components/streametric/card/Card';
import CardFooter from 'src/components/streametric/card/CardFooter';
import CardHeader from 'src/components/streametric/card/CardHeader';
import CardTitle from 'src/components/streametric/card/CardTitle';
import { cardFooter, cardHeader } from 'src/components/streametric/card/style';
import LogItem from './LogItem';
import LogDate from './LogDate';
import { FunctionComponent } from 'react';
import { LogItem as LogItemType } from '../types';
import { useResponsive } from 'src/hooks';
import RedoIcon from '@mui/icons-material/Redo';
import { Box, Button, Divider, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { useAuthentication } from 'src/features/authentication/context';
import { ACCESS_ROLES } from 'src/config';
import LogAttachments from './LogAttachments';

const LogCard: FunctionComponent<
  LogItemType & {
    onLogSelected: (log: LogItemType) => void;
    onLogRevert: (log: LogItemType) => void;
  }
> = ({ onLogSelected, onLogRevert, ...log }) => {
  const theme = useTheme();
  const { date, changedAt, changedBy, endDate, title, status, elements, attachments } = log;
  const isMobile = useResponsive('down', 'md');
  const isDeleted = status === 'inactive';
  const {
    getCurrentRole,
    customerId: { value: customerId },
    siteId: { value: siteId },
  } = useAuthentication();

  const paddingStyles = { padding: '16px 18px 16px 16px ' };

  const canRevertLog = () => {
    const currentRole = getCurrentRole(customerId, siteId);
    const isSuperAdmin = currentRole === ACCESS_ROLES.SUPER_ADMIN;
    const isAccountAdmin = currentRole === ACCESS_ROLES.ACCOUNT_ADMIN;

    return isSuperAdmin || isAccountAdmin;
  };
  return (
    <Card
      renderCardFooter={
        isDeleted
          ? () => (
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ padding: '8px 24px', backgroundColor: theme.palette.grey[200] }}
              >
                <Typography color="error" fontSize="12px">
                  Deleted by: {changedBy ?? 'System'}
                </Typography>
                {changedAt && (
                  <Typography color="error" fontSize="12px">
                    Deleted on: {dayjs(changedAt).format('DD/MM/YYYY')}
                  </Typography>
                )}
              </Stack>
            )
          : undefined
      }
      onClick={() => onLogSelected(log)}
      cardContentSx={{ padding: 0 }}
      sx={{
        padding: 0,
        cursor: 'pointer',
        '&:hover': { opacity: '0.9' },
        maxWidth: '100%',
        marginInline: 'initial',
      }}
    >
      <Stack>
        <CardHeader
          sx={{
            ...cardHeader,
            ...paddingStyles,
            //alignItems: isMobile && attachments?.length ? 'center' : 'initial',
          }}
        >
          <Stack
            justifyContent={isMobile || isDeleted ? 'initial' : 'space-between'}
            alignItems={isMobile || isDeleted ? 'initial' : 'center'}
            flex="1"
            sx={{ flexDirection: isMobile || isDeleted ? 'column' : 'row' }}
          >
            <CardTitle>{title}</CardTitle>
            <LogDate startDate={date} endDate={endDate} />
          </Stack>
          {attachments && isMobile ? (
            <LogAttachments
              log={log.id}
              singleView
              attachments={attachments.map((el: any, index: number) => ({
                ...el,
                order: index + 1,
              }))}
            />
          ) : (
            <></>
          )}
          {isDeleted && canRevertLog() ? (
            <Button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onLogRevert(log);
              }}
              variant="text"
            >
              <Stack alignItems="center">
                <SvgIcon sx={{ color: '#566369' }} component={RedoIcon} />
                <Typography fontSize="12px" sx={{ color: '#566369' }}>
                  Restore
                </Typography>
              </Stack>
            </Button>
          ) : (
            <></>
          )}
        </CardHeader>
        {!isMobile && <Divider />}
      </Stack>
      <CardFooter
        sx={{
          ...cardFooter,
          ...paddingStyles,
        }}
      >
        <Stack flexDirection="row">
          <Stack
            sx={{ justifyContent: 'flex-start', flexDirection: 'row', flexWrap: 'wrap' }}
            flexGrow="1"
          >
            {Array.isArray(elements) && elements.length ? (
              elements
                .map((element: any) => (element?.type === 'section' ? element?.fields : element))
                .flat()
                .filter(
                  (parameter: any) =>
                    parameter.type !== 'attachment' &&
                    Boolean(
                      Array.isArray(parameter.value) ? parameter.value.length : parameter.value
                    )
                )
                .map((parameter: any) => (
                  <LogItem
                    sx={{ flexBasis: isMobile ? '100%' : '25%' }}
                    key={parameter.id}
                    label={parameter.name}
                    {...parameter}
                  />
                ))
            ) : (
              <></>
            )}
          </Stack>
          {Array.isArray(attachments) && attachments.length ? (
            <Box
              sx={{ display: isMobile ? 'none' : 'block' }}
              flexGrow="0"
              flexBasis="20%"
              flexShrink="0"
            >
              <LogAttachments
                log={log.id}
                attachments={attachments.map((el: any, index: number) => ({
                  ...el,
                  order: index + 1,
                }))}
              />
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      </CardFooter>
    </Card>
  );
};

export default LogCard;
