import { useEffect, useState } from 'react';
import { WET_WELL_TAG_NAME, HEIGHT } from '../config';
import { AssetType } from '../types/types';
import { getIndexOfLastElement, convertToNumber } from '../utils';
import { useWebSockets } from './useWebSockets';

type useWetWellDataArgumentsTypes = {
  asset: AssetType;
  customerId: string | null;
};

export const useWaterLevelData = ({ asset }: useWetWellDataArgumentsTypes) => {
  const [waterLevel, setWaterLevel] = useState<number | null>(null);

  const assetAssignedTag = asset?.assignedTags?.find((tag) => tag?.tagName === WET_WELL_TAG_NAME);
  const { messages } = useWebSockets(assetAssignedTag?.assignedGatewayId!);
  const indexOfLastElement = getIndexOfLastElement(messages);
  const dataSocket = messages[indexOfLastElement];
  const gatewayInputs = dataSocket?.payload.data.onCreatedReading.gateway_inputs;
  const findInput = gatewayInputs?.find(
    (input: { id: string | undefined }) => input.id === assetAssignedTag?.assignedTagId
  );

  const assetProperty = asset?.assetProperties?.find((property) => property.name === HEIGHT);
  const alarms = asset?.assignedAlarmTags || [];

  useEffect(() => {
    if (findInput) {
      const toNumber = convertToNumber(findInput.value);
      const roundedWaterLevel = toNumber !== false ? Math.round(toNumber * 10) / 10 : -1;
      setWaterLevel(roundedWaterLevel);
    }
  }, [findInput]);
  return {
    waterLevel: waterLevel !== null ? Number(waterLevel.toFixed(1)) : null,
    height: Number(assetProperty?.value),
    alarms,
  };
};
