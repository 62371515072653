import { ReactNode, useRef } from 'react';
import { Box, Dialog, useTheme } from '@mui/material';
import {
  StyledChartWrapper,
  boxIcon,
  iconsWrapper,
  StyledBoxPanIcon,
  StyledChartHeader,
} from './style';
import { TimeFrameBarOptions } from 'src/features/trends/components/timeframe-bar-options';
import {
  ZoomInIcon,
  PanIcon,
  ZoomOutIcon,
  DisabledPanIcon,
  HamburgerIcon,
  HomeIcon,
} from 'src/assets';
import { useHtmlElement, useResponsive, useToggle } from 'src/hooks';
import { MenuPopoverOptions } from 'src/features/trends/components/menu-popover-options';
import { GraphSeriesType } from 'src/features/trends/types/readings';
import { AlarmData } from 'src/features/alarms';
import { exportAsPNG } from 'src/utilities';
import { TimeOptionType } from 'src/features/trends/types';
import { TimeframePopover } from 'src/features/trends/components/timeframe-popover/TimeframePopover';
import { SelectedBoxType } from 'src/features/trends/components/timeframe-bar-options/types';
import { SessionStorageService } from 'src/features/authentication/utils';
import { AddChartModalContent } from 'src/features/trends/components';
import { SelectedInputType } from 'src/features/trends/types/selected-input';
import { GetAllResponse } from 'src/services/sites';
import { getTimestampsBasedOnTimeBox } from 'src/features/trends/components/line-chart-trend/utils';
import { DateRangeComponent } from '../date-range';

type Props = {
  children: ReactNode;
  zoomOutHandler: () => void;
  zoomInHandler: () => void;
  onResetZoomHandler: () => void;
  onResetTimestamp: () => void;
  isPanAndZoomActive: boolean;
  togglePanAndZoom: () => void;
  pointChartData?: GraphSeriesType[];
  alarms?: AlarmData[];
  removeChart?: (chartId: string) => void;
  id: string;
  timestamps: any;
  onChangeTimestapHandler: (values: [Date, Date]) => void;
  onSelectTimestampHandler: (days: number) => void;
  selectTabletTimeOptionHandler: (timeOption: TimeOptionType) => void;
  setTimestamps: (timestamps: any) => void;
  sessionStorageKey: string;
  selectedBoxSessionStorageKey: string;
  sites: { value: GetAllResponse[]; loaded: boolean };
  onSelectEventHandler: (selectedInputs: SelectedInputType[], id: string) => void;
  selectedBox: TimeOptionType;
  setSelectedBox: (value: TimeOptionType) => void;
  isSyncTimeframe: boolean;
  toggleSyncTimeframe: () => void;
  changeTimeFormatHandler: (fromDate: Date, toDate: Date) => void;
};

export const ChartWrapper = ({
  children,
  zoomInHandler,
  zoomOutHandler,
  onResetZoomHandler,
  onResetTimestamp,
  isPanAndZoomActive,
  togglePanAndZoom,
  alarms,
  pointChartData,
  removeChart,
  id,
  timestamps,
  onChangeTimestapHandler,
  onSelectTimestampHandler,
  selectTabletTimeOptionHandler,
  setTimestamps,
  sessionStorageKey,
  selectedBoxSessionStorageKey,
  sites,
  onSelectEventHandler,
  selectedBox,
  setSelectedBox,
  isSyncTimeframe,
  toggleSyncTimeframe,
  changeTimeFormatHandler,
}: Props) => {
  const { htmlElement, addHtmlElement, removeHtmlElement } = useHtmlElement();
  const theme = useTheme();
  const iconColor = theme.palette.grey[500];
  const chartRef = useRef(null);
  const { state, onToggle } = useToggle();

  const onSelectBoxHandler = (value: SelectedBoxType) => {
    setSelectedBox(value);
    SessionStorageService.set(selectedBoxSessionStorageKey, value);

    const timestampsBasedOnSelectedTimeBox = getTimestampsBasedOnTimeBox(value);
    const fromDate = timestampsBasedOnSelectedTimeBox?.fromDate;
    const toDate = timestampsBasedOnSelectedTimeBox?.toDate;

    if (value !== 'custom') changeTimeFormatHandler(fromDate.toDate(), toDate.toDate());
  };

  const isTablet = useResponsive('down', 'lg');

  const onRemoveHandler = () => {
    if (removeChart && id) removeChart(id);
    onClosePopover();
  };

  const onOpenPopover = (event: any) => {
    addHtmlElement(event.currentTarget);
  };

  const onClosePopover = () => {
    removeHtmlElement();
  };

  const handleDownloadPNGClick = () => {
    onClosePopover();
    exportAsPNG(chartRef);
  };

  const onSelectDataHandler = () => {
    onToggle();
    onClosePopover();
  };

  const onResetHandler = () => {
    onResetZoomHandler();
    onResetTimestamp();
  };
  return (
    <StyledChartWrapper theme={theme} ref={chartRef} isTablet={isTablet}>
      <Dialog open={state} onClose={onToggle} maxWidth="lg">
        <AddChartModalContent
          onSelectEventHandler={onSelectEventHandler}
          onToggleHandler={onToggle}
          isParticularChart
          chartId={id}
          sites={sites}
        />
      </Dialog>
      <StyledChartHeader isTablet={isTablet} isSyncTimeframe={isSyncTimeframe}>
        {!isSyncTimeframe && isTablet ? (
          <TimeframePopover
            timestamps={timestamps}
            onSelectTimestampHandler={onSelectTimestampHandler}
            selectTabletTimeOptionHandler={selectTabletTimeOptionHandler}
            setTimestamps={setTimestamps}
            sessionStorageKey={sessionStorageKey}
            onResetZoomHandler={onResetZoomHandler}
            selectedBox={selectedBox}
            changeTimeFormatHandler={changeTimeFormatHandler}
          />
        ) : null}

        {!isSyncTimeframe && !isTablet ? (
          <Box sx={{ marginRight: '9rem', display: 'flex', alignItems: 'center' }}>
            <TimeFrameBarOptions
              onSelectTimestampHandler={onSelectTimestampHandler}
              selectedBox={selectedBox}
              onSelectBoxHandler={onSelectBoxHandler}
              onResetZoomHandler={onResetZoomHandler}
            />
            <DateRangeComponent
              onSelectBoxHandler={onSelectBoxHandler}
              onChangeTimestapHandler={onChangeTimestapHandler}
              timestamps={timestamps}
              changeTimeFormatHandler={changeTimeFormatHandler}
            />
          </Box>
        ) : null}

        <Box sx={iconsWrapper}>
          <Box sx={boxIcon} onClick={zoomInHandler}>
            <ZoomInIcon />
          </Box>
          <Box sx={boxIcon} onClick={zoomOutHandler}>
            <ZoomOutIcon />
          </Box>
          <Box sx={boxIcon} onClick={onResetHandler}>
            <HomeIcon fill={iconColor} />
          </Box>
          <StyledBoxPanIcon
            onClick={togglePanAndZoom}
            isPanAndZoomActive={isPanAndZoomActive}
            theme={theme}
          >
            {isPanAndZoomActive ? <PanIcon /> : <DisabledPanIcon />}
          </StyledBoxPanIcon>
          <Box onClick={onOpenPopover} data-sm="chart-burger-menu" sx={boxIcon}>
            <HamburgerIcon fill={iconColor} />
          </Box>
          <MenuPopoverOptions
            htmlElement={htmlElement}
            onClosePopover={onClosePopover}
            pointChartData={pointChartData}
            alarms={alarms}
            isTimelineChart={false}
            handleDownloadPNGClick={handleDownloadPNGClick}
            onRemoveHandler={onRemoveHandler}
            onSelectDataHandler={onSelectDataHandler}
            isSyncTimeframe={isSyncTimeframe}
            toggleSyncTimeframe={toggleSyncTimeframe}
          />
        </Box>
      </StyledChartHeader>
      {children}
    </StyledChartWrapper>
  );
};
