import { FunctionComponent, useEffect, useState } from 'react';
import { AssetField as AssetFieldType, ICustomFieldBaseProps } from '../types';
import { Box, TextField } from '@mui/material';
import { RHFAutocomplete } from 'src/components/minimals/form';
import useFieldSpacing from '../hooks/useFieldSpacing';
import assetsDropdown from '../../asset-details/data/asset_types.json';
import { api } from 'src/api.client';
import { useAuthentication } from 'src/features/authentication/context';
import { useFormContext } from 'react-hook-form';

type Asset = {
  assetName: string;
  id: string;
  assetType: string;
};

const AssetField: FunctionComponent<ICustomFieldBaseProps<AssetFieldType>> = ({
  field,
  fieldsLength,
  disabled,
}) => {
  const fieldClass = useFieldSpacing(fieldsLength);
  const { watch, setValue } = useFormContext();
  const { customerId, siteId } = useAuthentication();
  const [error, setError] = useState<boolean>(false);
  const [assets, setAssets] = useState<Asset[]>([]);
  const assetsValue = watch('assets');

  useEffect(() => {
    if (
      Array.isArray(assetsValue) &&
      assetsValue.some((element) => typeof element === 'string') &&
      assets.length
    ) {
      setValue(
        'assets',
        assets
          .filter((asset) => assetsValue.findIndex((assetValue) => asset.id === assetValue) !== -1)
          .map((asset) => ({ label: asset.assetName, value: asset.id }))
      );
    }
  }, [assetsValue, setValue, assets]);

  useEffect(() => {
    if (customerId.loaded && siteId.loaded) {
      (async () => {
        try {
          const response = await api.get<Asset[]>(
            '/sites/' + customerId.value + '/sites/' + siteId.value + '/assets'
          );
          const assetTypes =
            field.assetTypes && field.assetTypes.length
              ? field.assetTypes
              : assetsDropdown.map((assetType) => assetType.value);
          setAssets(
            response.data.filter(
              (asset) => assetTypes.findIndex((assetType) => asset.assetType === assetType) !== -1
            )
          );
        } catch (err) {
          setError(true);
        }
      })();
    }
  }, [customerId, siteId, field.assetTypes]);

  return (
    <Box sx={fieldClass}>
      <RHFAutocomplete
        disabled={disabled}
        name="assets"
        multiple
        options={assets.map((asset) => ({ label: asset.assetName, value: asset.id }))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assets"
            error={error}
            helperText={error ? 'Failed to fetch assets' : undefined}
            inputProps={{
              ...params.inputProps,
              'data-sm': 'asset-type-input',
              //readOnly: isMobile,
            }}
          />
        )}
      />
    </Box>
  );
};

export default AssetField;
