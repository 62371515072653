import { Option } from 'src/features/formRenderer/types';

type PumpModalData = {
  date: string;
  site: string;
  asset: string;
  runtime: string;
  cycles: string;
  flow: string;
  efficiency: string;
  energyConsumption: string;
};

export enum AlarmType {
  INFO = 'info',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

type DataType = {
  averageDailyEfficiency?: number;
  dailyCycles?: number;
  dailyEnergyConsumption?: number;
  dailyRuntime?: number;
  dailyVolumetricFlow?: number;
};

type PumpData = {
  siteName: string;
  assetName: string;
  data: DataType;
};

export enum ReportType {
  PDF = 'PDF',
  ASSET = 'ASSET',
  DATA = 'DATA',
}

type Report = {
  id: string;
  reportType: ReportType;
  name: string;
  createdBy: { id: string; firstName: string; lastName: string };
  updatedAt: string;
  scheduleType: string;
  isFavorite: boolean;
};

type FilterItem = {
  key: string;
  label: string | JSX.Element;
};

type ColumnItem = {
  name: string;
  label: string;
  value: boolean;
};

interface FilterItemProps {
  label: string;
  name: string;
  onChange: (checked: boolean, name: string) => void;
  value: boolean;
}
type FilterGroup = {
  name: string;
  label: string;
};
type FilterItemType = {
  name: string;
  label: string;
  value: boolean;
};
type TimeFrameSelectValue = [string, string];
type AssetProperty = {
  id: string;
  asset: {
    type: string;
    property: Omit<Option, 'order'>;
  };
  aggregation: Omit<Option, 'order'>;
  columnStatistics: Record<string, boolean>;
};
type TagData = {
  siteId: string;
  siteName: string;
  rawInputName: string;
  gatewayId: string;
  tagId: string;
  tagName: string;
  rawUnit: string;
};
type AssetPropertyOption = {
  value: string;
  label: string;
  available: boolean;
};
type TimeFrameToDateFunction = () => [string, string];
type TimeFrameValue = {
  value: string;
  label: string;
  parseToDate: TimeFrameToDateFunction;
};
type FormDataType = {
  [key: string]: string | boolean | any[] | FormDataType;
};
type DataColumn = {
  id: string;
  tag: TagData;
  aggregation: Omit<Option, 'order'>;
  columnStatistics: Record<string, boolean>;
};

type ReportTemplateType = 'DATA' | 'ASSET';

interface BaseReportTemplate {
  name: string;
  timezone: string;
  timeframe: {
    start: string;
    end: string;
  };
  dataAggregation: string;
}

interface DataReportTemplate extends BaseReportTemplate {
  columns: {
    date: boolean;
    time: boolean;

    custom: {
      siteId: string;
      siteName: string;
      tagId: string;
      unit: string;
      tagName: string;
    }[];
  };
}

interface AssetReportTemplate extends BaseReportTemplate {
  columns: {
    default: {
      date: boolean;
      site_name: boolean;
      site_type: boolean;
      asset_name: boolean;
      asset_type: boolean;
    };

    custom: {
      site_id: string;
      site_name: string;
      tag_id: string;
      unit: string;
      tag_name: string;
    }[];
  };
}

type ApiResourceState = 'INITIAL' | 'LOADING' | 'SUCCESS' | 'ERROR';

type ColumnStatistics = Record<string, boolean>;

interface IAddColumnDialogProps {
  onSubmit: () => void;
  onCancel: () => void;
}

type AssetStateType = {
  assetProperties: AssetProperty[] | null;
  currentAssetProperty: AssetProperty | null;
  currentState: Record<string, any>;
};
type RangePumpData = PumpData & {
  date: string;
};

type AssetType = {
  assetName: string;
  data: DataType;
};

type Assets = {
  [key: string]: AssetType;
};

type SiteType = {
  siteName: string;
  assets: Assets;
};

type AssetReportApplyedFilters = {
  selectedItems: string[];
  assetTypes: string[];
  sitesTypes: string[];
};

export type {
  ColumnStatistics,
  ColumnItem,
  FormDataType,
  IAddColumnDialogProps,
  AssetPropertyOption,
  ReportTemplateType,
  AssetProperty,
  FilterItem,
  PumpModalData,
  PumpData,
  RangePumpData,
  SiteType,
  TagData,
  DataReportTemplate,
  TimeFrameValue,
  ApiResourceState,
  BaseReportTemplate,
  AssetReportTemplate,
  AssetType,
  DataColumn,
  AssetStateType,
  FilterGroup,
  Report,
  TimeFrameSelectValue,
  FilterItemType,
  FilterItemProps,
  AssetReportApplyedFilters,
};
