import {
  AddNotificationGroupType,
  CheckUserAccess,
  OneClickAcknowledgement,
} from 'src/types/alarms';
import {
  api,
  ALARMS_URL,
  EVENTS,
  NOTIFICATIONS_URL,
  CUSTOMER_EVENTS,
  ACTIVE_EVENTS,
  NOTIFICATION_GROUPS_URL,
  CHECK_USERS_SITES_ACCESS,
} from '../api';

const getAll = async (customerId: string, limit?: number, skipTo?: string, siteId?: string) => {
  const skipToValue: string = skipTo && skipTo !== '' ? `&skipTo=${skipTo}` : '';
  const siteIdValue: string = siteId && siteId !== '' ? `&siteId=${siteId}` : '';

  const response = await api({
    method: 'get',
    url: limit
      ? `${ALARMS_URL}/${customerId}${CUSTOMER_EVENTS}?limit=${limit}${skipToValue}${siteIdValue}`
      : `${ALARMS_URL}/${customerId}${CUSTOMER_EVENTS}`,
  });

  return response.data;
};

const getAllBySiteId = async (
  customerId: string,
  siteId: string,
  fromDate: string,
  toDate: string
) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}/${siteId}${EVENTS}?fromDate=${fromDate}&toDate=${toDate}`,
  });

  return response.data;
};

const getAlarmPopupInfo = async (customerId: string, siteId: string, eventId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}/${siteId}${EVENTS}/${eventId}`,
  });

  return response.data;
};

const getAlarmPopupNotifications = async (customerId: string, eventId: string) => {
  const response = await api({
    method: 'get',
    url: `${NOTIFICATIONS_URL}/${customerId}/${eventId}`,
  });

  return response.data;
};

const acknowledgeAlarm = async (
  customerId: string,
  siteId: string,
  eventId: string,
  idToken: string
) => {
  const response = await api({
    method: 'post',
    url: `${ALARMS_URL}/${customerId}/${siteId}${EVENTS}/${eventId}/acknowledge`,
    headers: {
      'X-Streametric-User': idToken,
    },
  });

  return response.data;
};

const getAlarmCount = async (customerId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}${EVENTS}/count`,
  });

  return response.data;
};

const getActiveAlarmCount = async (customerId: string) => {
  const response = await api({
    method: 'get',
    url: `${ALARMS_URL}/${customerId}${ACTIVE_EVENTS}/count`,
  });

  return response.data;
};

const getAllNotificationGroups = async (customerId: string) => {
  const response = await api({
    method: 'get',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}`,
  });

  return response.data;
};

const addNotificationGroup = async (customerId: string, data: AddNotificationGroupType) => {
  const response = await api({
    method: 'post',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}`,
    data,
  });

  return response.data;
};

const getNotificationGroupById = async (customerId: string, notificationId: string) => {
  const response = await api({
    method: 'get',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}/${notificationId}`,
  });

  return response.data;
};

const updateNotificationGroup = async (
  customerId: string,
  notificationId: string,
  data: AddNotificationGroupType
) => {
  const response = await api({
    method: 'patch',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}/${notificationId}`,
    data,
  });

  return response.data;
};

const deleteNotificationGroup = async (customerId: string, notificationId: string) => {
  const response = await api({
    method: 'delete',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${customerId}/${notificationId}`,
  });

  return response.data;
};

const checkUsersSitesAccess = async (data: CheckUserAccess) => {
  const response = await api({
    method: 'post',
    url: `/v2${NOTIFICATION_GROUPS_URL}/${CHECK_USERS_SITES_ACCESS}`,
    data,
  });

  return response.data;
};

const oneClickAcknowledge = async (eventId: string, data: OneClickAcknowledgement) => {
  const response = await api({
    method: 'post',
    url: `/v2/events/${eventId}/token-acknowledge`,
    data,
  });

  return response.data;
};

export const AlarmsService = {
  getAll,
  getAllBySiteId,
  getAlarmPopupInfo,
  getAlarmPopupNotifications,
  acknowledgeAlarm,
  getAlarmCount,
  getActiveAlarmCount,
  getAllNotificationGroups,
  addNotificationGroup,
  checkUsersSitesAccess,
  getNotificationGroupById,
  updateNotificationGroup,
  deleteNotificationGroup,
  oneClickAcknowledge,
};
