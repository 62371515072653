import { StackProps, Switch, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { StyledColumn } from '../../style';

const ToggleColumn: FunctionComponent<
  Omit<StackProps, 'sx' | 'alignItems' | 'justifyContent' | 'flexDirection' | 'onClick'> & {
    onToggle: (name: string, value: boolean) => void;
    name: string;
    label: string;
    enabled: boolean;
  }
> = ({ enabled, label, name, onToggle, ...props }) => {
  const disabledClass = !enabled ? { backgroundColor: '#F4F4F7' } : {};
  return (
    <StyledColumn
      sx={{ cursor: 'pointer', paddingInline: '16px', paddingBlock: '9px', ...disabledClass }}
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      onClick={() => onToggle(name, !enabled)}
      {...props}
    >
      <Typography fontWeight="600" variant="body2" color={!enabled ? '#8998A0' : 'primary'}>
        {label}
      </Typography>
      <Switch size="small" checked={!!enabled} />
    </StyledColumn>
  );
};

export default ToggleColumn;
