import { replace } from 'lodash';

const getAttachments = (data: any) => {
  const attachments: any = {};
  Object.entries(data).forEach(([key, value]: any) => {
    if (typeof value === 'object') {
      Object.entries(value).forEach(([key, value]: any) => {
        if (key.includes('_attachment')) {
          attachments[replace(key, '_attachment', '')] = Array.isArray(value)
            ? value.filter((item: any) => !!item.file).map((item: any) => item.file)
            : [];
        }
      });
    }
    if (key.includes('_attachment')) {
      attachments[replace(key, '_attachment', '')] = Array.isArray(value)
        ? value.filter((item: any) => !!item.file).map((item: any) => item.file)
        : [];
    }
  });

  return attachments;
};

export default getAttachments;
