import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { IconsTypesProps } from 'src/assets/icons/types';
import { useLocation, useNavigate } from 'react-router';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';
import { NavItem } from '../navItem';

type Props = {
  title: string;
  url: string;
  icon: React.FunctionComponent<IconsTypesProps>;
  greyColor: string;
  activeItem: string;
  onSetActiveItemHandler: (newActiveItem: string) => void;
};

export const ExtendedNavItem = ({
  icon,
  title,
  greyColor,
  url,
  activeItem,
  onSetActiveItemHandler,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    const currentQueryParams = location.search;
    navigate(`${url}${currentQueryParams}`);
    onSetActiveItemHandler(title);
  };

  const { get } = useQueryParamsActions();
  const templateId = get('templateId');
  const isHistory = title === 'History';

  return (
    <>
      {isHistory && !templateId ? (
        <Tooltip title="No saved reports">
          <Box sx={{ width: '100%' }}>
            <NavItem title={title} icon={icon} greyColor={greyColor} activeItem={activeItem} />
          </Box>
        </Tooltip>
      ) : (
        <NavItem
          title={title}
          icon={icon}
          greyColor={greyColor}
          activeItem={activeItem}
          onClickHandler={onClickHandler}
        />
      )}
    </>
  );
};
