import { FunctionComponent } from 'react';
import SidebarWrapper from '../sidebar/SidebarWrapper';
import ColumnToggle from '../sidebar/columns/ToggleColumn';
import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import TimeFrameSelect from '../sidebar/sidebar-inputs/TimeFrameSelect';
import DateAggregationSelect from '../sidebar/sidebar-inputs/DateAggregationSelect';
import AssetPropertiesList from './asset-properties-list/AssetPropertiesList';
import { ASSET_REPORT_DEFAULT_COLUMNS, ASSET_AGGREGATION_OPTIONS } from '../../config/config';
import { useFormContext } from 'react-hook-form';
import { FilterIcon } from 'src/assets/icons/FilterIcon';
import { StyledFilterButton, filtersBox, filterBtnBox } from './style';
import { TrashBinIcon } from 'src/assets/icons/TrashBinIcon';
import { SelectedFiltersList } from '../selected-filters-list';
import { useTemplateState } from '../../context/templateContext';
import { useOutletContext } from 'react-router';
import { useFilterSites } from '../../context/sitesContext';
import { CustomWaterLoading } from 'src/components';

interface IAssetReportsSidebarProps {
  onSubmit: () => void;
  toggleSidebar: (state: boolean) => void;
  isFilterBtnActive: boolean;
  applyFiltersHandler: any;
  clearAllFiltersHandler: () => void;
}

const AssetReportsSidebar: FunctionComponent<IAssetReportsSidebarProps> = ({
  onSubmit,
  applyFiltersHandler,
  toggleSidebar,
  isFilterBtnActive,
  clearAllFiltersHandler,
}) => {
  const { setValue, watch } = useFormContext();
  const { checkTemplateInitialState } = useTemplateState();
  const { isLoading } = useFilterSites();
  const isInitial = checkTemplateInitialState(watch());
  const { disableActions } = useOutletContext<any>();
  const theme = useTheme();

  const toggleColumn = (name: string, value: boolean) => {
    setValue(`data.columns.default.${name}`, value);
  };

  return (
    <SidebarWrapper
      renderFooterContent={(onCollapse) => (
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button
            disabled={isInitial || disableActions || isLoading}
            onClick={() => {
              onCollapse();
              onSubmit();
            }}
            variant="contained"
            color="primary"
          >
            Preview
          </Button>
        </Stack>
      )}
    >
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            minHeight: '350px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomWaterLoading />
        </Box>
      ) : (
        <>
          <Stack gap="20px">
            <TimeFrameSelect
              onChange={(value) => setValue('data.timeFrame', value)}
              value={watch('data.timeFrame')}
            />
            <DateAggregationSelect
              value={watch('data.aggregationInterval')}
              onChange={(event) =>
                setValue('data.aggregationInterval', event.target!.value as string)
              }
              timeFrames={ASSET_AGGREGATION_OPTIONS}
            />
          </Stack>
          <Stack sx={filtersBox}>
            <Stack sx={filterBtnBox}>
              <StyledFilterButton
                onClick={() => toggleSidebar(true)}
                endIcon={<FilterIcon />}
                variant="text"
                size="small"
                isActive={isFilterBtnActive}
                theme={theme}
              >
                Filter
              </StyledFilterButton>
              {isFilterBtnActive && (
                <Button
                  onClick={clearAllFiltersHandler}
                  color="error"
                  variant="text"
                  startIcon={<TrashBinIcon fill={theme.palette.error.main} />}
                >
                  Clear All
                </Button>
              )}
            </Stack>
            {isFilterBtnActive && <SelectedFiltersList applyFiltersHandler={applyFiltersHandler} />}
          </Stack>

          {/* Default columns */}
          <Stack gap="8px">
            <Typography variant="body1">Default Columns</Typography>
            {ASSET_REPORT_DEFAULT_COLUMNS.map((columnItem) => (
              <ColumnToggle
                key={columnItem.name}
                name={columnItem.name}
                label={columnItem.label}
                enabled={(watch('data.columns.default') || {})[columnItem.name]}
                onToggle={toggleColumn}
              />
            ))}
          </Stack>
          <Divider sx={{ marginBlock: '16px' }} />
          {/* Asset properties */}
          <AssetPropertiesList
            assetProperties={watch('data.columns.custom') || []}
            onChange={(assetProperties) => setValue('data.columns.custom', assetProperties)}
          />
        </>
      )}
    </SidebarWrapper>
  );
};

export default AssetReportsSidebar;
