import { Theme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type StyledChartWrapperType = {
  theme: Theme;
  isTablet: boolean;
};

const StyledChartWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTablet',
})(({ theme, isTablet }: StyledChartWrapperType) => ({
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)',
  borderRadius: '16px',
  padding: isTablet ? '2rem 0.5rem' : '2rem 1rem',
  marginTop: '2rem',
  marginBottom: '2rem',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
}));

const StyledChartHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTablet' && prop !== 'isSyncTimeframe',
})(({ isTablet, isSyncTimeframe }: { isTablet: boolean; isSyncTimeframe: boolean }) => ({
  display: 'flex',
  justifyContent: isTablet && !isSyncTimeframe ? 'space-between' : 'flex-end',
  alignItems: 'center',
  marginBottom: ' 2rem',
  padding: '0 1.2rem',
  position: 'relative',
  zIndex: '10',
}));

const boxIcon = {
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const iconsWrapper = {
  display: 'flex',
  alignItems: 'center',
};

const StyledBoxPanIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPanAndZoomActive',
})(({ theme, isPanAndZoomActive }: { theme: Theme; isPanAndZoomActive: boolean }) => ({
  cursor: 'pointer',
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: isPanAndZoomActive ? theme.palette.primary.lighter : 'none',
  borderRadius: '8px',
}));

export { StyledChartWrapper, boxIcon, iconsWrapper, StyledChartHeader, StyledBoxPanIcon };
