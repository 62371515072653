import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useAuthentication } from 'src/features/authentication/context';
import { AccessRoles } from 'src/features/user-account-details/types';
import {
  ROOT_HEADER,
  SITES_DASHBOARD_PATH,
  SITES_MANAGEMENT_PATH,
  USER_ACCOUNTS_PATH_ROOT,
  SITE_INFO_PATH,
  GATEWAY_PATH_ROOT,
  ASSET_PATH_ROOT,
  REPORTS,
  ALARMS_PATH,
  ALARM_RULES_PATH_ROOT,
  TRENDS_PATH,
  INTEGRATIONS_PATH,
  CONTROLLERS_PATH_ROOT,
  SITES_MAP_PATH,
} from 'src/routes/config';

import {
  OVERVIEW_NAV_TITLE,
  SETTINGS,
  SITES_NAV_TITLE,
  REPORTS_NAV_TITLE,
  USER_MANAGEMENT_NAV_TITLE,
  ALARMS_NAV_TITLE,
  TRENDS_NAV_TITLE,
} from '../config';
import { NavActiveItem, NavItemProps } from '../types/types';

export const useNavigation = (navHeaderItems: NavItemProps[]) => {
  const location = useLocation();
  const { customerId, getCurrentRole } = useAuthentication();

  const isAccountAdmin = getCurrentRole(customerId.value, null) === AccessRoles.ACCOUNT_ADMIN;
  const isSuperAdmin = getCurrentRole(customerId.value, null) === AccessRoles.SUPER_ADMIN;

  const isDashboardPage = location.pathname.includes(SITES_DASHBOARD_PATH);
  const isUserAccountPage = location.pathname.includes(USER_ACCOUNTS_PATH_ROOT);
  const isSiteManagementPage =
    location.pathname.includes(SITES_MANAGEMENT_PATH) && !isDashboardPage;
  const isSiteMapPage = location.pathname.includes(SITES_MAP_PATH) && !isDashboardPage;
  const isSiteInfoPage = location.pathname.includes(SITE_INFO_PATH);
  const isGatewaysPage = location.pathname.includes(GATEWAY_PATH_ROOT);
  const isAssetsPage = location.pathname.includes(ASSET_PATH_ROOT);
  const isReportPage = location.pathname.includes(REPORTS);
  const isAlarmPage = location.pathname.includes(ALARMS_PATH);
  const isAlarmRules = location.pathname.includes(ALARM_RULES_PATH_ROOT);
  const isAnalyticsPage = location.pathname.includes(TRENDS_PATH);
  const isDataSourcePage = location.pathname.includes(INTEGRATIONS_PATH);
  const isControllersPage = location.pathname.includes(CONTROLLERS_PATH_ROOT);

  const [activeItems, setActiveItems] = useState<NavActiveItem[]>(
    navHeaderItems.map(({ title }: NavItemProps) => ({
      title: title,
      isActive: false,
      isToggle: false,
    }))
  );

  const onClickHandler = useCallback((title: string) => {
    setActiveItems((prevState) =>
      prevState.map((item) => {
        if (item.title === title)
          return {
            ...item,
            isActive: true,
            isToggle: true,
          };
        return { ...item, isActive: false, isToggle: false };
      })
    );
  }, []);

  useEffect(() => {
    setActiveItems(() =>
      navHeaderItems.map(({ title, isToggle }) => {
        if (title === ROOT_HEADER) {
          return {
            title: title,
            isActive: true,
            isToggle: isToggle,
          };
        }
        if (isUserAccountPage && title === USER_MANAGEMENT_NAV_TITLE) {
          return {
            title: title,
            isActive: true,
            isToggle: true,
          };
        }
        if ((isSiteManagementPage || isSiteMapPage) && title === SITES_NAV_TITLE) {
          return {
            title: title,
            isActive: true,
            isToggle: true,
          };
        }
        if (isDashboardPage && title === OVERVIEW_NAV_TITLE) {
          return {
            title: title,
            isActive: true,
            isToggle: isToggle,
          };
        }
        if (
          (isSiteInfoPage ||
            isGatewaysPage ||
            isAssetsPage ||
            isAlarmRules ||
            isDataSourcePage ||
            isControllersPage) &&
          title === SETTINGS
        ) {
          return {
            title: title,
            isActive: true,
            isToggle: true,
          };
        }
        if (isReportPage && title === REPORTS_NAV_TITLE) {
          return {
            title: title,
            isActive: true,
            isToggle: true,
          };
        }
        if (isAlarmPage && title === ALARMS_NAV_TITLE) {
          return {
            title: title,
            isActive: true,
            isToggle: isSuperAdmin || isAccountAdmin ? true : isToggle,
          };
        }
        if (isAnalyticsPage && title === TRENDS_NAV_TITLE) {
          return {
            title: title,
            isActive: true,
            isToggle: isToggle,
          };
        }

        return {
          title: title,
          isActive: false,
          isToggle: isToggle,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return { activeItems, onClickHandler };
};
