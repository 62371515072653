import { Theme, styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

type StylesTypes = {
  theme: Theme;
  isHover: boolean;
};
type StylesTypesItem = {
  theme: Theme;
  isHover: boolean;
  isMobile: boolean;
};

const StyledWidgetModalItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHover' && prop !== 'isMobile',
})(({ theme, isHover, isMobile }: StylesTypesItem) => ({
  maxWidth: isMobile ? '225px' : '100%',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.default,
  padding: '14px 16px 17px 16px',
  boxShadow:
    '0px 0px 1.3088px rgba(145, 158, 171, 0.2), 0px 7.85279px 15.7056px -2.6176px rgba(145, 158, 171, 0.12)',
  borderRadius: '10px',
  border: isHover ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent',
  position: 'relative',
}));

const widgetBox = {
  height: '164px',
  maxWidth: '194px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '13px 0 15px 0',
  border: '0.7px solid rgba(145, 158, 171, 0.32)',
  borderRadius: '8px',
};

const StyledWidgetName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isHover',
})(({ theme, isHover }: StylesTypes) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: isHover ? theme.palette.primary.main : 'inherit',
  margin: '20px 0 9px 0',
  fontSize: '14px',
}));

const textFiled = {
  '.MuiOutlinedInput-root': {
    padding: '0 5px 5px 5px 5px',
  },
  '.css-t0xxjf-MuiInputBase-input-MuiOutlinedInput-input': {
    height: '8px',
  },
  '.css-1q60rmi-MuiAutocomplete-endAdornment': {
    top: 'calc(50% - 13px)',
  },
  '.css-l4j3u1-MuiFormLabel-root-MuiInputLabel-root': {
    transform: 'translate(18px, 10px) scale(1)',
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
  },
};

export { StyledWidgetModalItem, widgetBox, StyledWidgetName, textFiled };
