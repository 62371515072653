import { WidgetTypeEnum, AssetType } from '../types/types';
import { TagResponse } from 'src/features/alarm-rules/types';
import { GetDataSourceInputsResponse } from 'src/services/sites/types';
import { Controller } from 'src/types';

export const getWidgetOptions = (
  widgetType: WidgetTypeEnum | undefined,
  gatewayInputs: TagResponse[],
  assets: AssetType[],
  dataSourceInputs: GetDataSourceInputsResponse[],
  controllers: Controller[]
) => {
  if (!widgetType) return [];

  const discreteDataTypes = ['mode', 'alarm', 'state'];

  if (widgetType.includes(WidgetTypeEnum.LINEAR_GRAPH)) {
    const gatewayInputsData = gatewayInputs.filter(
      (x: TagResponse) => !x.hidden && discreteDataTypes.includes(x.dataType)
    );
    return [...gatewayInputsData, ...dataSourceInputs];
  }

  if (widgetType.includes(WidgetTypeEnum.SPIRAL_DOTS_GRAPH)) {
    const gatewayInputsData = gatewayInputs.filter(
      (x: TagResponse) => !x.hidden && !discreteDataTypes.includes(x.dataType)
    );

    return [...gatewayInputsData, ...dataSourceInputs];
  }

  if (widgetType === WidgetTypeEnum.BARS_GRAPH) {
    const pumpArray: AssetType[] = [];
    const filteredArray = assets.filter((asset) => asset.assetType === 'PUMP');
    filteredArray.forEach((option) => {
      const runtimeAsset = {
        ...option,
        assetName: `${option.assetName} - Runtime`,
        dataType: 'RUNTIME',
        id: `${option.id}-RUNTIME`,
      };
      const cycleCountAsset = {
        ...option,
        assetName: `${option.assetName} - Cycle Counts`,
        dataType: 'CYCLE_COUNT',
        id: `${option.id}-CYCLE_COUNT`,
      };
      pumpArray.push(runtimeAsset);
      pumpArray.push(cycleCountAsset);
    });
    return pumpArray;
  }

  if (widgetType === WidgetTypeEnum.CONTROLLER_WIDGET) {
    return controllers;
  }

  return assets.filter((asset) => asset.assetType === widgetType);
};
