import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import LogCard from './LogCard';
import { LogItem } from '../types';
import { random } from 'lodash';

const LogsList: FunctionComponent<{
  logs: LogItem[];
  onLogSelected: (log: LogItem) => void;
  onLogRevert: (log: LogItem) => void;
}> = ({ logs, onLogSelected, onLogRevert }) => (
  <Stack flex="1" overflow="auto">
    {logs.map((logItem) => (
      <LogCard
        onLogSelected={onLogSelected}
        onLogRevert={onLogRevert}
        key={logItem.id ?? random().toString()}
        {...logItem}
      />
    ))}
  </Stack>
);

export default LogsList;
