import { ASSET, initialFilters, SEPARATOR, SITE } from '../config/config';
import { AssetReportApplyedFilters } from '../types';

const getArrayIds = (selectedItems: string[]): string[] =>
  selectedItems.map((item) => {
    const splitItem = item.split(SEPARATOR);
    return splitItem[2] ?? '';
  });

export const getFilterResponse = (
  data: any,
  sites: any,
  assets: any
): AssetReportApplyedFilters => ({
  assetTypes: data?.assetFilters?.assetTypes || initialFilters.assetTypes,
  sitesTypes: data?.siteFilters?.siteTypes || initialFilters.sitesTypes,
  selectedItems:
    !data?.siteFilters?.sites && !data?.assetFilters?.assets
      ? initialFilters.selectedItems
      : [
          ...(data?.siteFilters?.sites?.length
            ? data?.siteFilters?.sites
                ?.map((site: any) => {
                  const siteData = sites.find((el: any) => el.id === site);

                  if (!siteData) return false;

                  return `site|${siteData.siteName}|${site}`;
                })
                .filter(Boolean)
            : []),
          ...(data?.assetFilters?.assets?.length
            ? data?.assetFilters?.assets
                ?.map((site: any) => {
                  const siteData = assets.find((el: any) => el.id === site);

                  if (!siteData) return false;

                  return `asset|${siteData.assetName}|${site}`;
                })
                .filter(Boolean)
            : []),
        ],
});

export const getFilterDto = (data: AssetReportApplyedFilters) => {
  const selectedAssets = data.selectedItems.filter((item) => item.includes(ASSET));
  const selectedSites = data.selectedItems.filter((item) => item.includes(SITE));
  const sitesIds = getArrayIds(selectedSites);
  const assetIds = getArrayIds(selectedAssets);

  const siteFilters = {
    sites: sitesIds.filter(Boolean),
    siteTypes: data.sitesTypes,
  };
  const assetFilters = {
    assets: assetIds.filter(Boolean),
    assetTypes: data.assetTypes,
  };

  return { siteFilters, assetFilters };
};
