import _, { isNull, replace } from 'lodash';
import { LogCommentPayload } from '../types';

const parseNaNToZero = (value: string) => replace(value, /NaN/g, '0');

const parseLogPayload = (
  data: any,
  formId: string,
  allowComments: boolean,
  logId?: string | null
) => {
  const payload = {
    formId: logId ? undefined : formId,
    date: data.date_from,
    endDate: data.date_to,
    assetIds: data.assets
      ? data.assets.map((asset: { label: string; value: string }) => asset.value)
      : [],
    comments: logId
      ? null
      : Array.isArray(data.comments) &&
        data.comments.map((comment: LogCommentPayload) => comment?.content || false).filter(Boolean)
          .length
      ? data.comments.map((comment: LogCommentPayload) => comment?.content || false).filter(Boolean)
      : null,
    values: Object.entries(data)
      .map(([key, value]: any) => {
        if (
          key === 'date_from' ||
          key === 'date_to' ||
          key === 'assets' ||
          key === 'comments' ||
          key === 'id'
        )
          return null;

        if (key.includes('_attachment')) {
          if (!value.length) return null;
          return {
            id: _.replace(key, '_attachment', ''),
            value: Array.isArray(value) ? value.filter((item: any) => !item?.file) : [],
          };
        }

        if (typeof value === 'object' && !isNull(value)) {
          if (Array.isArray(value)) {
            return {
              id: key,
              value: value,
            };
          }
          return Object.entries(value).map(([key, value]: any) => {
            if (Array.isArray(value)) {
              return {
                id: key,
                value,
              };
            }
            const stringifiedValue = value ? value + '' : null;
            if (key.includes('_attachment')) {
              if (!value.length) return null;
              return {
                id: _.replace(key, '_attachment', ''),
                value: Array.isArray(value) ? value.filter((item: any) => !item?.file) : [],
              };
            }
            return {
              id: key,
              value: stringifiedValue
                ? stringifiedValue.includes(':')
                  ? parseNaNToZero(stringifiedValue)
                  : stringifiedValue
                : null,
            };
          });
        }

        const stringifiedValue = value ? value + '' : null;
        return {
          id: key,
          value: stringifiedValue
            ? stringifiedValue.includes(':')
              ? parseNaNToZero(stringifiedValue)
              : stringifiedValue
            : null,
        };
      })
      .flat()
      .filter((pair: any) => !!pair && !!pair.value) as {
      id: string;
      value: any;
    }[],
  };

  if (!allowComments || !payload.comments) delete payload.comments;
  if (logId) delete payload.formId;

  return payload;
};

export default parseLogPayload;
