import { SyntheticEvent, useCallback, useState, useEffect, ChangeEvent } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  StyledDrawer,
  threeBox,
  titleBox,
  titleStyle,
  titleHeaderBox,
  StledBtnsBox,
  searchFiled,
} from './style';
import { FilterCategoryItem } from '../filter-category-item';
import { GetAllResponse } from 'src/services/sites';
import { ASSET_REPORTS_FILTER_CATEGORIES } from '../../config/config';
import { AssetReportApplyedFilters } from '../../types';
import { useDebounce } from 'src/hooks/useDebounce';
import FilterTreeCategoryList from '../filter-tree-category-list/FilterTreeCategoryList';
import { useGetSites } from 'src/hooks';
import { useAuthentication } from 'src/features/authentication/context';
import { useFilterSites } from '../../context/sitesContext';

type Props = {
  isOpen: boolean;
  sites: { value: GetAllResponse[] | null; loaded: boolean };
  toggleSidebar: (state: boolean) => void;
  applyFiltersHandler: (data: AssetReportApplyedFilters) => void;
  appliedFilters: AssetReportApplyedFilters;
  isInitialState: boolean;
};

type SelectedItems = {
  assetTypes: string[];
  siteTypes: string[];
};

const initialSelectedItems = {
  assetTypes: [],
  siteTypes: [],
};

export const SideBarFilter = ({
  isOpen,
  sites,
  toggleSidebar,
  applyFiltersHandler,
  appliedFilters,
}: Props) => {
  const [treeSelectedItems, setTreeSelectedItems] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedItems>(initialSelectedItems);
  const { sites: allSites } = useFilterSites();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const {
    customerId: { value: customerId },
  } = useAuthentication();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { sites: searchedSites, isLoading } = useGetSites({
    debouncedSearchTerm: debouncedSearchTerm,
    customerId: customerId,
    siteTypes: selectedItems.siteTypes,
  });

  useEffect(() => {
    setSelectedItems({
      assetTypes: appliedFilters.assetTypes,
      siteTypes: appliedFilters.sitesTypes,
    });
    setTreeSelectedItems(appliedFilters.selectedItems);
  }, [appliedFilters]);

  const handleSelectedItemsChange = useCallback((event: SyntheticEvent, ids: string[]): void => {
    setTreeSelectedItems(ids);
  }, []);

  const onSelectChecboxItems = (
    type: 'assetTypes' | 'siteTypes',
    value: string,
    isSelected: boolean
  ) => {
    setSelectedItems((prev) => {
      const updatedList = isSelected
        ? [...prev[type], value]
        : prev[type].filter((item) => item !== value);
      return { ...prev, [type]: updatedList };
    });
  };

  const onSelectAllItems = (
    type: 'assetTypes' | 'siteTypes',
    options: string[],
    isAllSelected: boolean
  ) => {
    setSelectedItems((prev) => ({
      ...prev,
      [type]: isAllSelected ? [] : options,
    }));
  };

  const onApply = () => {
    applyFiltersHandler({
      selectedItems: treeSelectedItems,
      assetTypes: selectedItems.assetTypes,
      sitesTypes: selectedItems.siteTypes,
    });

    toggleSidebar(false);
  };

  const onClear = () => {
    setTreeSelectedItems(allSites);
  };

  const onHandleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <StyledDrawer
      anchor="right"
      open={isOpen}
      onClose={(event, reason) => {
        toggleSidebar(false);
      }}
    >
      <Box>
        <Box sx={titleHeaderBox}>
          <Typography variant="h5">Filters</Typography>
        </Box>
        {ASSET_REPORTS_FILTER_CATEGORIES.map(({ type, options, stateName }) => (
          <FilterCategoryItem
            key={type}
            title={type}
            options={options}
            selectedOptions={selectedItems}
            stateName={stateName}
            onSelectChecboxItems={onSelectChecboxItems}
            onSelectAllItems={onSelectAllItems}
          />
        ))}

        <Box>
          <Box sx={titleBox}>
            <Typography sx={titleStyle}>Sites / Assets</Typography>
          </Box>
          <Box sx={threeBox}>
            <TextField
              sx={searchFiled}
              value={searchTerm}
              size="small"
              placeholder="Search Site or Asset"
              onChange={onHandleInputChange}
            />
            <FilterTreeCategoryList
              selectedItems={treeSelectedItems}
              handleSelectedItemsChange={handleSelectedItemsChange}
              sites={sites}
              searchedSites={searchedSites}
              isLoading={isLoading}
              selectedAssetTypes={selectedItems.assetTypes}
            />
          </Box>
        </Box>

        <StledBtnsBox>
          <Button sx={{ flex: 1 }} variant="outlined" color="error" onClick={onClear}>
            Clear All
          </Button>
          <LoadingButton
            onClick={onApply}
            sx={{ flex: 1 }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Apply
          </LoadingButton>
        </StledBtnsBox>
      </Box>
    </StyledDrawer>
  );
};
