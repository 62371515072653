import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useAuthentication } from 'src/features/authentication/context';
import { SitesService } from 'src/services/sites';
import { SEPARATOR, SITE } from '../config/config';
import { getFilterResponse } from '../helper';
import { AssetService } from 'src/services';

const sitesContext = createContext<any>({});

interface ISitesContextProviderProps {
  isAsset: boolean;
  template: any;
  children: JSX.Element;
  methods: any;
  onLoad: () => void;
  filtersLoaded: boolean;
}

const SitesContextProvider: FunctionComponent<ISitesContextProviderProps> = ({
  isAsset,
  children,
  template,
  methods,
  onLoad,
  filtersLoaded,
}) => {
  const [isLoading, setIsLoading] = useState(isAsset ? true : false);
  const [sites, setSites] = useState<any>([]);
  const [sitesNoParsed, setSitesNoParsed] = useState<any>([]);
  const [initialFilters, setInitialFilters] = useState<any>(null);
  const {
    customerId: { value: customerId },
  } = useAuthentication();

  useEffect(() => {
    if (isAsset && !filtersLoaded) {
      (async () => {
        try {
          setIsLoading(true);
          const response = await SitesService.getAll({
            customerId: customerId!,
            limit: 500,
          });

          setSitesNoParsed(response);
          setSites(
            response.map((site: any) => `${SITE}${SEPARATOR}${site.siteName}${SEPARATOR}${site.id}`)
          );
          onLoad();

          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
      })();
    }
  }, [customerId, onLoad, filtersLoaded, setInitialFilters, isAsset, template, methods]);

  useEffect(() => {
    (async () => {
      if (template.state && template.state.filters && !isLoading && sitesNoParsed.length) {
        const assets = await AssetService.getAllGlobal(customerId!);
        const parsedFilters = getFilterResponse(template.state.filters, sitesNoParsed, assets);

        setInitialFilters(parsedFilters);
        methods.setValue('filters', parsedFilters);
      }
    })();
  }, [template, isLoading, methods, sites, sitesNoParsed, customerId]);
  return (
    <sitesContext.Provider value={{ isLoading, sites, initialFilters }}>
      {children}
    </sitesContext.Provider>
  );
};

export const useFilterSites = () => useContext(sitesContext);

export default SitesContextProvider;
