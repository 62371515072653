import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { ICustomFieldBaseProps, SelectField as SelectFieldType } from '../types';
import { RHFSelect } from 'src/components/minimals/form';
import useFieldSpacing from '../hooks/useFieldSpacing';
import { getErrorMessage } from '../utils/getErrorMessage';
import { useFormContext } from 'react-hook-form';

const SelectField: FunctionComponent<ICustomFieldBaseProps<SelectFieldType>> = ({
  field,
  fieldsLength,
  disabled,
}) => {
  const fieldClass = useFieldSpacing(fieldsLength);
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box sx={fieldClass}>
      <RHFSelect
        disabled={disabled}
        helperText={getErrorMessage(errors, field.id, field.validations)}
        rules={field.validations}
        multiple={field.isMulti}
        options={field.options.sort((a, b) => a.order - b.order) || []}
        label={field.name}
        testId={field.id.toLocaleLowerCase()}
        name={field.id}
      />
    </Box>
  );
};

export default SelectField;
