import { useEffect, useRef, useState } from 'react';
import { initialFilters } from '../config/config';
import { AssetReportApplyedFilters } from '../types';
import { useFormContext } from 'react-hook-form';
import { useFilterSites } from '../context/sitesContext';
import useQueryParamsActions from 'src/hooks/useQueryParamsActions';

export const useFilters = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const { setValue, watch } = useFormContext();
  const initialSetSitesRef = useRef(false);
  const { get } = useQueryParamsActions();
  const templateId = get('templateId');
  const { isLoading, sites } = useFilterSites();

  let appliedFilters = watch('filters') || initialFilters;
  const isFilterBtnActive = !isInitialState;

  const toggleSidebar = (newOpen: boolean) => {
    setIsOpen(newOpen);
  };

  useEffect(() => {
    if (!initialSetSitesRef.current && sites?.length && !templateId && !isLoading) {
      setValue('filters', {
        ...appliedFilters,
        selectedItems: [...(appliedFilters.selectedItems || []), ...sites],
      });
      initialSetSitesRef.current = true;
    }
  }, [sites, setValue, isLoading, appliedFilters, templateId]);

  useEffect(() => {
    setIsInitialState(
      appliedFilters?.selectedItems?.filter((item: string) => item.includes('site')).length ===
        sites?.length &&
        !appliedFilters?.selectedItems?.filter((item: string) => item.includes('asset')).length &&
        appliedFilters?.assetTypes?.length === initialFilters?.assetTypes?.length &&
        appliedFilters?.sitesTypes?.length === initialFilters?.sitesTypes?.length
    );
  }, [appliedFilters, sites]);

  const applyFiltersHandler = (data: AssetReportApplyedFilters) => {
    setValue('filters', {
      ...watch('filters'),
      ...data,
    });
  };

  const clearAllFiltersHandler = () => {
    setValue('filters', { ...initialFilters, selectedItems: sites });
  };

  return {
    isOpen,
    toggleSidebar,
    applyFiltersHandler,
    clearAllFiltersHandler,
    setIsInitialState,
    isInitialState,
    appliedFilters,
    isFilterBtnActive,
  };
};
