import { useAuthentication } from 'src/features/authentication/context';
import { ElementProps } from '../../../types/element';
import { BuildReportsRouteElement } from './BuildReportsRouteElement';
import { useEffect } from 'react';
import TemplateCacheContextProvider from 'src/features/reports/context/templateCacheContext';

export const BuildReportsElement = ({ isSuperAdmin }: ElementProps) => {
  const { isCustomerAccessAllowed, loggedIn, customerId } = useAuthentication();

  useEffect(
    () => () => {
      sessionStorage.removeItem('report_type');
    },
    []
  );

  return (
    <TemplateCacheContextProvider>
      <BuildReportsRouteElement
        loggedIn={loggedIn}
        isSuperAdmin={isSuperAdmin}
        isCustomerAccessAllowed={isCustomerAccessAllowed}
        customerId={customerId}
      />
    </TemplateCacheContextProvider>
  );
};
